import React from "react"
import HeaderPreorder from "../components/HeaderPreorder"
import SectionContactPreorder from "../components/SectionContactPreorder"
import Footer from "../components/Footer"
import { IndexQuery } from "../components/Query/IndexPage"
import { connect } from "react-redux"

import "./index-clear.scss"
import "./index.scss"

const PreOrderPage = ({ language, dispatch }) => {
  const data = IndexQuery()

  let dataLang = {}
  let gymsDataLang = []
  let usersDataLang = []

  data.allContentfulIndex.nodes.forEach(node => {
    if (language === node.node_locale) {
      dataLang = node
    }
  })

  data.allContentfulSectionForGyms.nodes.forEach(node => {
    if (language === node.node_locale) {
      gymsDataLang.push(node)
    }
  })

  data.allContentfulSectionForUsers.nodes.forEach(node => {
    if (language === node.node_locale) {
      usersDataLang.push(node)
    }
  })

  return (
    <>
      <HeaderPreorder
        indexPage={true}
        data={dataLang.header}
        dispatch={dispatch}
        language={language}
      />
      <main className="content">
        

        <SectionContactPreorder data={dataLang} language={language} />
      </main>
      <Footer data={dataLang.footer} />
    </>
  )
}

export default connect(state => state.languageReducer, null)(PreOrderPage)
